/** @format */

import { AbstractComponent } from "../AbstractComponent";
import $ from "jquery";
import Cookies from "js-cookie";
import jsQR from "jsqr-es6";
import { getUrlParam, ordinalSuffix, sleep, truncate } from "../toolkit";
import {
    checkTag,
    getOrderQuantity,
    orderReturned,
    reprintLabel,
    setScanIds,
    unlinkTags,
} from "../requests/post";
import { LightboxComponent } from "./LightboxComponent";

export interface Point {
    x: number;
    y: number;
}

export interface ScanResult {
    data: string;
    cornerPoints: Point[];
}

export interface ScanHistory {
    scanIds: string[];
    orderNumber: string;
    scanTime: number;
    postalCode: string;
    petName: string;
    unlinked: boolean;
}

enum Feeling {
    Positive,
    Negative,
    Neutral,
}

enum ScanType {
    Instruction,
    Tag,
}

enum Mode {
    Pack,
    Reprint,
    Unlink,
    Check,
    Returns,
}

let orderNumberRE = /^\d{16}$/;
let scanIdRE = /^[a-zA-Z\d]{8}$/;

export class QrScannerComponent extends AbstractComponent {
    public static selector: string = "qr-scanner-component";
    private video: HTMLVideoElement;
    private messageElem: HTMLElement;
    private scansPerSecond: number = 4;
    private expectedScanType: ScanType = ScanType.Instruction;
    private orderNumber: string = "";
    private scanIDs: string[] = [];
    private expectedScanIDs: number;
    private stationId: string;
    private scannerName: string;
    private periodic: any;
    private blocked: boolean = false;
    private history: ScanHistory[] = [];
    private mode: Mode = Mode.Pack;
    private shouldPlaySounds: boolean = false;
    private idleTime: number = 1500;
    private geo: string = "US";

    public init(): QrScannerComponent {
        this.video = document.querySelector("video") as HTMLVideoElement;
        this.messageElem = document.querySelector(
            "[data-message]"
        ) as HTMLElement;

        if (getUrlParam("fast")) {
            this.idleTime = 500;
            this.scansPerSecond = 10;
        }

        if (getUrlParam("geo")) {
            this.geo = getUrlParam("geo");
        }

        this.setupListeners();
        this.startStreaming();
        this.loadState();
        this.reset();

        return this;
    }

    private setupListeners(): void {
        this.video.addEventListener("play", () => {
            this.periodic = setInterval(
                this.periodicScan.bind(this),
                1000 / this.scansPerSecond
            );
        });

        this.video.addEventListener("pause", async () => {
            clearInterval(this.periodic);
            await this.startStreaming();
        });

        $("[data-show-settings]").on("click", () => {
            $("[data-settings]").show();
            this.playSound(Feeling.Positive);
            this.playSound(Feeling.Negative);
        });

        $("[data-save-settings]").on("click", () => {
            this.scannerName = $("#scanner_name").val() as string;
            this.stationId = $("#station_id").val() as string;

            let beforeMode = this.mode;
            let rawMode = $("#mode").val() as string;

            this.mode = parseInt(rawMode) as Mode;

            if (beforeMode !== this.mode) {
                this.reset();
            }

            this.saveState();
            $("[data-settings]").hide();
        });

        $("[data-close-settings]").on("click", () => {
            $("[data-settings]").hide();
        });

        $("[data-show-history]").on("click", () => {
            let historyElem = $("[data-history-content]");
            historyElem.empty();

            let elem = $(`
                <div class="scan__history__close" data-close-history></div>
            `);
            historyElem.append(elem);

            let lastFive = this.history.slice(-5).reverse();

            lastFive.forEach(historyItem => {
                let name = truncate(historyItem.petName, 20);
                let extraButtonAttr = "";

                if (historyItem.unlinked) {
                    name = window.gettext(`${name} (unlinked)`);
                    extraButtonAttr = "class='display: none;'";
                }

                let message = window.gettext(
                    `${historyItem.scanIds.length} tag(s), going to ${historyItem.postalCode}`
                );

                let elem = $(`
                    <div class="scan__history__item" data-order-id="${historyItem.orderNumber}">
                        <b>${name}</b>
                        ${message}
                        <div class="scan__history__item__buttons">
                            <i class="fas fa-print" data-history-reprint ${extraButtonAttr}></i>
                            <i class="fas fa-unlink" data-history-unlink ${extraButtonAttr}></i>
                        </div>
                    </div>
                `);
                historyElem.append(elem);
            });

            $("[data-history]").show();
        });

        $("[data-history-content]").on("click", "[data-close-history]", () => {
            $("[data-history]").hide();
        });

        $("[data-history-content]").on(
            "click",
            "[data-history-reprint]",
            async event => {
                let clickedElem = $(event.currentTarget);

                let orderId = clickedElem
                    .closest("[data-order-id]")
                    .data("order-id") as string;

                clickedElem.addClass("check");
                await reprintLabel(orderId, this.stationId);
                await sleep(1000);
                clickedElem.removeClass("check");
            }
        );

        $("[data-history-content]").on(
            "click",
            "[data-history-unlink]",
            async event => {
                let clickedElem = $(event.currentTarget);
                let orderElem = clickedElem.closest("[data-order-id]");
                let orderId = orderElem.data("order-id") as string;

                clickedElem.addClass("check");
                await unlinkTags(orderId);
                await sleep(1000);
                clickedElem.removeClass("check");

                let nameElem = orderElem.find("b");
                let name = nameElem.text();
                nameElem.text(window.gettext(`${name} (unlinked)`));

                this.removeHistoryEntry(orderId);

                clickedElem.parent().hide();
            }
        );

        $("[data-toggle-sound]").on("click", () => {
            this.shouldPlaySounds = !this.shouldPlaySounds;
            $("[data-toggle-sound]").toggleClass("fa-volume-mute");
            $("[data-toggle-sound]").toggleClass("fa-volume-up");

            this.playSound(Feeling.Positive, true);
            this.playSound(Feeling.Negative, true);
        });

        $("[data-return-reason]").on("click", async event => {
            let clickedElem = $(event.currentTarget);
            let reason = clickedElem.data("return-reason") as string;

            await orderReturned(this.orderNumber, reason).catch(() => {
                this.displayMessage(
                    window.gettext("Failed to return."),
                    Feeling.Negative
                );
                this.playSound(Feeling.Negative);
                return;
            });

            $("[data-return]").hide();
            this.displayMessage(
                window.gettext("Successfully returned."),
                Feeling.Positive
            );
            this.playSound(Feeling.Positive);
            await sleep(this.idleTime);
            this.reset();
            this.blocked = false;
        });
    }

    private async startStreaming(): Promise<void> {
        let constraints = {
            facingMode: {
                exact: "environment",
            },
            zoom: true,
            advanced: [
                {
                    zoom: 2,
                    focusMode: "continuous",
                },
            ],
        };

        this.video.srcObject = await navigator.mediaDevices.getUserMedia({
            // @ts-ignore
            video: constraints,
            audio: false,
        });
        await this.video.play();
    }

    private async periodicScan(): Promise<void> {
        if (document.hidden || this.blocked) return; // camera will be started as soon as tab is in foreground
        let result = this.scan();
        if (result && !this.blocked) {
            this.blocked = true;
            let shouldBlock = await this.processData(result.data);

            if (!shouldBlock) {
                this.blocked = false;
            }
        }
    }

    private scan(): ScanResult {
        let canvas = document.createElement("canvas");

        if (this.video.videoWidth === 0) return null;

        canvas.width = this.video.videoWidth;
        canvas.height = this.video.videoHeight;

        const context = canvas.getContext("2d", { alpha: false })!;
        context.imageSmoothingEnabled = false;
        context.drawImage(
            this.video,
            0,
            0,
            canvas.width,
            canvas.height,
            0,
            0,
            canvas.width,
            canvas.height
        );
        let imageData = context.getImageData(
            0,
            0,
            canvas!.width,
            canvas!.height
        );

        let rgbaData = imageData["data"];
        let width = imageData["width"];
        let height = imageData["height"];
        let result = jsQR(rgbaData, width, height, {
            inversionAttempts: "attemptBoth",
            greyScaleWeights: {
                // weights for quick luma integer approximation (https://en.wikipedia.org/wiki/YUV#Full_swing_for_BT.601)
                red: 77,
                green: 150,
                blue: 29,
                useIntegerApproximation: true,
            },
        });

        if (result) {
            return {
                data: result.data,
                cornerPoints: [
                    result.location.topLeftCorner,
                    result.location.topRightCorner,
                    result.location.bottomRightCorner,
                    result.location.bottomLeftCorner,
                ],
            };
        } else {
            return null;
        }
    }

    private displayMessage(message: string, feeling: Feeling) {
        this.messageElem.innerText = message;
        let messageParent = this.messageElem.parentElement as HTMLElement;

        switch (feeling) {
            case Feeling.Positive:
                messageParent.classList.add("scan__message--positive");
                messageParent.classList.remove("scan__message--negative");
                break;
            case Feeling.Negative:
                messageParent.classList.add("scan__message--negative");
                messageParent.classList.remove("scan__message--positive");
                this.playSound(feeling);
                break;
            case Feeling.Neutral:
                messageParent.classList.remove("scan__message--positive");
                messageParent.classList.remove("scan__message--negative");
                break;
        }
    }

    private async processData(data: string): Promise<boolean> {
        let split = data.split("/");
        let scanID = split[split.length - 1];

        if (orderNumberRE.test(scanID)) {
            if (this.expectedScanType === ScanType.Tag) {
                this.displayMessage(
                    window.gettext("Instruction scanned - expected tag."),
                    Feeling.Negative
                );
                await sleep(1000);
                return;
            }

            if (this.mode === Mode.Reprint) {
                this.displayMessage(
                    window.gettext("Reprinting label..."),
                    Feeling.Positive
                );

                await reprintLabel(scanID, this.stationId);

                await sleep(this.idleTime);
                this.reset();
                return;
            } else if (this.mode === Mode.Unlink) {
                this.displayMessage(
                    window.gettext("Unlinking tags..."),
                    Feeling.Positive
                );

                await unlinkTags(scanID);
                this.removeHistoryEntry(scanID);

                await sleep(this.idleTime);
                this.reset();
                return;
            } else if (this.mode === Mode.Returns) {
                this.orderNumber = scanID;
                $(`[data-return=${this.geo}]`).show();
                return true;
            }

            let response = await getOrderQuantity(scanID, this.stationId);
            let error = response["error"];

            if (error) {
                this.displayMessage(error, Feeling.Negative);
                await sleep(1500);
                this.reset();
                return;
            }

            this.displayMessage(
                window.gettext("Instruction scanned - printing label..."),
                Feeling.Positive
            );

            let quantity = response["quantity"];

            this.orderNumber = scanID;
            this.expectedScanIDs = quantity;
            this.expectedScanType = ScanType.Tag;
            await sleep(this.idleTime);

            this.displayMessage(
                window.gettext(`Scan ${quantity} tag(s)`),
                Feeling.Neutral
            );
        } else if (scanIdRE.test(scanID)) {
            if (this.expectedScanType === ScanType.Instruction) {
                if (this.lastScanID() === scanID) {
                    await sleep(500);
                    return;
                }

                this.displayMessage(
                    window.gettext("Tag scanned - expected instruction."),
                    Feeling.Negative
                );
                await sleep(1000);
                return;
            }

            if (this.mode == Mode.Check) {
                this.displayMessage(
                    window.gettext("Checking tag..."),
                    Feeling.Neutral
                );

                let message = await checkTag(scanID);
                LightboxComponent.getDisplay(
                    message,
                    true,
                    null,
                    this.reset.bind(this)
                );

                return;
            }

            if (this.scanIDs.includes(scanID)) {
                await sleep(500);
                return;
            }

            this.scanIDs.push(scanID);
            let amountScanned = this.scanIDs.length;

            if (amountScanned < this.expectedScanIDs) {
                this.displayMessage(
                    window.gettext(
                        `Scan ${ordinalSuffix(amountScanned + 1)} tag`
                    ),
                    Feeling.Neutral
                );
                return;
            } else {
                this.displayMessage(
                    window.gettext("Linking tags to order..."),
                    Feeling.Neutral
                );
            }

            let response = await setScanIds(
                this.orderNumber,
                this.scanIDs,
                this.scannerName
            );
            let error = response["error"];

            if (error) {
                this.displayMessage(error, Feeling.Negative);
                await sleep(1500);

                switch (response["error_code"]) {
                    case "order_not_found":
                        this.reset();
                        break;
                    case "duplicate_scan":
                        this.scanIDs = [];
                        this.displayMessage(
                            window.gettext(
                                `Scan ${this.expectedScanIDs} tag(s)`
                            ),
                            Feeling.Neutral
                        );
                        break;
                    case "duplicate_tag":
                        this.scanIDs = [];
                        this.displayMessage(
                            window.gettext(
                                `Scan ${this.expectedScanIDs} tag(s)`
                            ),
                            Feeling.Neutral
                        );
                        break;
                    case "invalid_tag":
                        this.scanIDs = [];
                        this.displayMessage(
                            window.gettext(
                                `Scan ${this.expectedScanIDs} tag(s)`
                            ),
                            Feeling.Neutral
                        );
                        break;
                    case "too_many_tags":
                        this.reset();
                        break;
                }
                return;
            }

            this.displayMessage(
                window.gettext("Successfully linked."),
                Feeling.Positive
            );
            this.playSound(Feeling.Positive);

            this.history.push({
                scanIds: this.scanIDs,
                scanTime: new Date().getTime(),
                postalCode: response["postal_code"] as string,
                petName: response["pet_name"] as string,
                orderNumber: this.orderNumber,
                unlinked: false,
            });
            this.saveState();

            await sleep(this.idleTime);
            this.reset();
        } else {
            await sleep(1000);
        }
    }

    private reset() {
        this.expectedScanType = ScanType.Instruction;
        this.orderNumber = "";
        this.scanIDs = [];
        this.expectedScanIDs = 0;

        let message = window.gettext("Scan Card To");

        if (this.mode === Mode.Pack) {
            message = window.gettext("Scan Card To Link Tags");
        } else if (this.mode === Mode.Reprint) {
            message = window.gettext("Scan Card To Reprint Label");
        } else if (this.mode === Mode.Unlink) {
            message = window.gettext("Scan Card To Unlink Tags");
        } else if (this.mode === Mode.Check) {
            message = window.gettext("Scan Tag To Check");
            this.expectedScanType = ScanType.Tag;
        } else if (this.mode === Mode.Returns) {
            message = window.gettext("Scan Card To Process Return");
        }

        this.displayMessage(message, Feeling.Neutral);
    }

    private playSound(feeling: Feeling, silent: boolean = false) {
        if (!this.shouldPlaySounds) return;

        let soundElem = $("[data-success-sound]")[0] as HTMLAudioElement;

        if (feeling === Feeling.Negative) {
            soundElem = $("[data-error-sound]")[0] as HTMLAudioElement;
        }

        soundElem.muted = silent;
        soundElem.play();
    }

    private lastScanID(): string {
        if (this.history.length === 0) return "";

        let lastHistoryItem = this.history[this.history.length - 1];
        let lastScanIds = lastHistoryItem["scanIds"];

        if (lastScanIds.length === 0) return "";

        return lastScanIds[lastScanIds.length - 1];
    }

    private saveState(): void {
        let state = {
            station_id: this.stationId,
            scanner_name: this.scannerName,
            mode: this.mode,
            history: this.history,
        };

        Cookies.set("qr_scanner_state", JSON.stringify(state));
    }

    private loadState(): void {
        let rawState = Cookies.get("qr_scanner_state");

        if (!rawState) {
            $("[data-settings]").show();
            return;
        }

        let state = JSON.parse(rawState);
        this.stationId = state["station_id"];
        this.scannerName = state["scanner_name"];
        this.mode = state["mode"];
        this.history = state["history"];

        if (this.history.length > 5) {
            this.history = this.history.slice(-5);
        }

        $("#station_id").val(this.stationId);
        $("#scanner_name").val(this.scannerName);
        $("#mode").val(this.mode);
    }

    private removeHistoryEntry(orderNumber: string): void {
        let historyEntry = this.history.find(historyItem => {
            return historyItem.orderNumber === orderNumber;
        });
        this.history.splice(this.history.indexOf(historyEntry), 1);

        this.saveState();
    }
}
