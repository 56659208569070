/** @format */

import { AbstractComponent } from "../AbstractComponent";

export class CountdownComponent extends AbstractComponent {
    public static selector: string = "countdown-component";
    private secondsLeft: number = 0;
    private counting: boolean = true;

    public init(): void {
        this.secondsLeft = parseInt(this.componentElement.data("seconds"));
        setInterval(this.count.bind(this), 1000);
    }

    private count(): void {
        if (!this.secondsLeft || !this.counting) {
            return;
        }

        this.secondsLeft--;
        this.componentElement.text(this.getSecondsLeftText());
    }

    private getSecondsLeftText(): string {
        let minutesLeft = Math.floor(this.secondsLeft / 60);
        let hoursLeft = Math.floor(minutesLeft / 60);

        if (hoursLeft > 24) {
            let daysLeft = Math.floor(hoursLeft / 24);
            let hoursAfterDays = hoursLeft - daysLeft * 24;
            let daysText = window.gettext(`${daysLeft} day`);
            let hoursText = window.gettext(`${hoursAfterDays} hour`);

            if (daysLeft > 1) {
                daysText = window.gettext(`${daysLeft} days`);
            }

            if (hoursLeft > 1) {
                hoursText = window.gettext(`${hoursAfterDays} hours`);
            }

            return `${daysText}, ${hoursText}`;
        }

        if (minutesLeft > 60) {
            let minutesAfterHours = minutesLeft - hoursLeft * 60;
            let hoursText = window.gettext(`${hoursLeft} hour`);
            let minutesText = window.gettext(`${minutesAfterHours} minute`);

            if (hoursLeft > 1) {
                hoursText = window.gettext(`${hoursLeft} hours`);
            }

            if (minutesLeft > 1) {
                minutesText = window.gettext(`${minutesAfterHours} minutes`);
            }

            return `${hoursText}, ${minutesText}`;
        }

        let secondsAfterMinutes = this.secondsLeft - minutesLeft * 60;
        let minutesText = window.gettext(`${minutesLeft} minute`);
        let secondsText = window.gettext(`${secondsAfterMinutes} second`);

        if (minutesLeft > 1) {
            minutesText = window.gettext(`${minutesLeft} minutes`);
        }

        if (secondsAfterMinutes > 1) {
            secondsText = window.gettext(`${secondsAfterMinutes} seconds`);
        }

        return `${minutesText}, ${secondsText}`;
    }
}
