import $ from 'jquery';
import { firePixels, getContext } from "../toolkit";

const handleFAQs = () => {
    document.querySelectorAll('.thankyou-faq__question').forEach(item => {
        item.addEventListener('click', () => {
            const faqItem = item.closest('.thankyou-faq__item');
            const answer = faqItem?.querySelector('.thankyou-faq__answer') as HTMLElement;
            const toggleIcon = item.querySelector('.thankyou-faq__toggle-icon') as HTMLElement;

            if (answer && toggleIcon) {
                answer.classList.toggle('thankyou-faq__answer--active');
                toggleIcon.classList.toggle('thankyou-faq__toggle-icon--active');

                if (answer.classList.contains('thankyou-faq__answer--active')) {
                    toggleIcon.classList.remove('fa-plus');
                    toggleIcon.classList.add('fa-minus');
                } else {
                    toggleIcon.classList.remove('fa-minus');
                    toggleIcon.classList.add('fa-plus');
                }
            }
        });
    });
}

const handleCartDropdown = () => {
    const header = document.querySelector('.thankyou-cart-mobile__header');
    const content = document.querySelector('.thankyou-cart-mobile__content');
    const toggle = header?.querySelector('.thankyou-cart-mobile__toggle');
    const chevron = toggle?.querySelector('.fa-chevron-down');

    if (header && content && toggle && chevron) {
        header.addEventListener('click', () => {
            content.classList.toggle('thankyou-cart-mobile__content--active');
            toggle.classList.toggle('thankyou-cart-mobile__toggle--active');
        });
    }
}

export const setup = () => {
    $(() => {
        handleFAQs();
        handleCartDropdown();

        let thanksContext = getContext();
        firePixels(
            "CompletePayment",
            thanksContext["value"],
            thanksContext["email"]
        );
    });
}
