/** @format */

import "../polyfill";
import $ from "jquery";
import "jquery-validation";

import { loginEmail, sendAuthCodeNoLogin } from "../requests/post";
import { replacePlaceholders } from "../toolkit";

let form = $("[data-login-form]");
let explainer = $("[data-login-explainer]");
let verify = $("[data-login-code]");

function validate() {
    (form as any).validate({
        onKeyup: true,
        rules: {
            email: {
                required: true,
                email: true,
            },
        },
    } as any);
}

function setupListeners() {
    form.on("submit", (event: JQuery.TriggeredEvent) => {
        event.preventDefault();
        login();
    });

    $("[data-submit]").on("click", () => {
        login();
    });
}

function _show_whoopsie() {
    form.hide();
    explainer.text(
        window.gettext(`Whoops, something went wrong. Please try again later.`)
    );
}

async function login() {
    if (!(form as any).valid()) {
        return;
    }

    let email = $("input[name=email]").val() as string;

    if (form.data("type") == "code") {
        await sendAuthCodeNoLogin(email);
        form.hide();
        verify.show();
        replacePlaceholders(verify, { email: email });
        return;
    }

    return loginEmail(email)
        .then(response => {
            form.children()
                .not(explainer)
                .hide();
            explainer.text(
                window.gettext(
                    `We've sent an email with a magic link to ${email}. Simply click the link in the email to log in.`
                )
            );
        })
        .catch(error => {
            if (error.name === "NotOkResponseError") {
                _show_whoopsie();
            }
        });
}

export function setup() {
    validate();
    setupListeners();
}
